import React from 'react';
import styled from 'styled-components';
import { Wrap, Sidebar, Content } from '@components/content';
import Container from '@components/container';
import { HeroInner } from '@components/hero';
import { Grey, Spacer } from '@components/utils';
import Link from '@components/link';

export default () => {
  return (
    <FourOfour>
      <Spacer size={6.5} md={3} sm={2} />
      <HeroInner>
        <Container>
          <Wrap>
            <Sidebar></Sidebar>
            <Content>
              <h1>
                404
                <br /> Something went wrong.
              </h1>
              <Spacer size={6} md={6} sm={4} />
              <Grey>
                <p>Looks like the page you’re trying to reach is longer available.</p>
                <Link url="/">View Work</Link>
              </Grey>
            </Content>
          </Wrap>
        </Container>
      </HeroInner>
      <Spacer size={5} md={2} sm={0} />
    </FourOfour>
  );
};

const FourOfour = styled.div`
  flex: 1;
`;
